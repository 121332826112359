<section class="field__wrapper">
  <label
    [for]="properties.id"
    [class.field__label--required]="properties.required"
    class="field__label"
  >
    <span>{{ properties.label }}</span>
  </label>
  <section
    [class.field__inner--error]="hasErrors"
    class="field__inner"
  >
    <input
      [(ngModel)]="maskedValue"
      (keydown)="onKeyDown($event)"
      (input)="inputSubject.next($event)"
      (change)="onChange($event)"
      (paste)="onInput($event)"
      (focus)="onFocus()"
      (blur)="onBlur()"
      [attr.data-testid]="dataTestId"
      [id]="properties.id"
      [name]="properties.id"
      [required]="properties.required"
      class="form-control"
      maxLength="19"
      minLength="13"
      #credit_card
    />
    <span #suffix_icon class="w-[36px] h-[24px] field__suffix-icon"></span>
  </section>
</section>
<section class="field__errors">
  @if (hasErrors) {
    <span
      @errorVisibility
      [attr.data-testid]="dataTestId + '-error'"
      class="warning"
    >
            {{ field.errors }}
        </span>
  }
</section>

export const visaRegex = /^4[0-9]{3}/
export const masterCardRegex = /^(5[1-5][0-9]{2}|2(2[2-9][1-9]|2[3-9][0-9]|[3-6][0-9]{2}|7[01][0-9]|720))$/
export const amexRegex = /^3[47][0-9]{2}/
export const discoverRegex = /^6(?:011|5[0-9]{2})/

export const getLastFour = (cardNumber: string): string => {
  return cardNumber && cardNumber.length > 4 ? cardNumber.slice(-4) : cardNumber
}

export const getCardType = (cardNumber: string): string => {
  const firstFourDigits = cardNumber.length > 4 ? cardNumber.slice(0, 4) : cardNumber;

  if (visaRegex.test(firstFourDigits)) {
    return 'Visa';
  } else if (masterCardRegex.test(firstFourDigits)) {
    return 'Mastercard';
  } else if (amexRegex.test(firstFourDigits)) {
    return 'Amex';
  } else if (discoverRegex.test(firstFourDigits)) {
    return 'Dicover';
  } else {
    return 'Unknown';
  }
}

import { animate, style, transition, trigger } from '@angular/animations'
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  OnDestroy,
  ElementRef,
  AfterViewInit,
  OnInit
} from '@angular/core'
import { NgxMaskDirective } from 'ngx-mask'
import { debounceTime, Subject } from 'rxjs'
import type { FieldProps } from './credit-card-field.entity'
import { Field } from './credit-card-field.entity'
import { getCardType } from 'src/app/helpers/credit-card.helper'

@Component({
  selector: 'credit-card-field.field',
  animations: [
    trigger('errorVisibility', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('300ms', style({ opacity: 0 }))]),
    ]),
  ],
  templateUrl: './cc-field.component.html',
  styleUrl: './cc-field.component.scss',
})
export class CcFieldComponent implements OnChanges, AfterViewInit, OnDestroy, OnInit{
  @Input({ required: true, alias: 'props' }) properties!: FieldProps
  @Input({ required: true }) field!: Field
  @Output() fieldChange = new EventEmitter<Field>()
  @Output() cardNumberChange = new EventEmitter<string>();

  @ViewChild('credit_card') creditCardElement!: ElementRef
  @ViewChild('suffix_icon') suffixIconElement!: ElementRef
  readonly inputSubject = new Subject()

  private readonly numericRegex = /[0-9]/
  private readonly allowedKeys = [
    'ArrowLeft',
    'ArrowRight',
    'Backspace',
    'Delete',
    'Space',
    'Tab',
  ]
  private errors: string[] = []

  isFocused = false

  get dataTestId(): string {
    return `${this.properties.id}-input`
  }

  get hasErrors(): boolean {
    return Boolean(this.errors.length)
  }

  get maskedValue() {
    if (this.isFocused || this.field.value.length <= 4) {
      return this.formatCardNumber(this.field.value)
    }

    const cardValue = this.formatCardNumber(this.field.value);
    const masked = cardValue.substring(0, cardValue.length - 4).replace(/\d/g, '*') + cardValue.slice(-4)

    return masked
  }

  set maskedValue(value: string) {
    this.field.value = value
  }

  /**
   * @constructor
   * @description
   * Sets up the input field with a debounce time (300ms by default).
   */
  constructor() {
    this.inputSubject
      .pipe(debounceTime(300))
      .subscribe((event) => this.onInput(event as Event))
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['field']) {
      this.errors = this.field.errors
    }

    if (changes['field'] && !changes['field'].firstChange) {
      if (this.field.value.length >= 4) {
        this.setCardType(this.field.value)
      } else {
        this.removeCardTypes()
      }
    }
  }

  /**
   * @description
   * Angular lifecycle method that is called when the component's views has been initialized.
   * Evaluates the value to know the credit card brand.
   */
  ngAfterViewInit() {
    if (!this.field.value && this.field.value.length < 4) {
      this.removeCardTypes()
    }

    if (this.field.value.length >= 4) {
      this.setCardType(this.field.value)
    }
  }

  /**
   * @description
   * Angular lifecycle method that is called when the component is destroyed.
   * Unsubscribes from the inputSubject observable.
   */
  ngOnDestroy() {
    this.inputSubject.unsubscribe()
  }

  /**
   * @description
   * Handles the keydown event on the input field.
   * Prevents the default action of the event if the key is not a letter.
   * - If the key is a space, it prevents the default action.
   * Additionally, it removes the dot from the input value (macOS behavior).
   *
   * @param { KeyboardEvent } event - The keydown event.
   */
  onKeyDown(event: KeyboardEvent): void {
    if (
      !this.numericRegex.test(event.key) &&
      !this.allowedKeys.includes(event.code)
    ) {
      event.preventDefault()
    }
  }

  /**
   * @description
   * Handles the input event on the input field.
   * It validates the input value and updates the value model.
   *
   * @param { Event } event - The input event.
   */
  onInput(event: Event): void {
    const input = event.target as HTMLInputElement
    this.validate(input.value)

    if (!input.value && input.value.length < 4) {
      this.removeCardTypes()
    }

    if (input.value.length >= 4) {
      this.setCardType(input.value)
    }
  }

  /**
   * @description
   * Handles the change event on the input field.
   * The input value is trimmed removing trailing spaces.
   *
   * @param { Event } event - The input event.
   */
  onChange(event: Event): void {
    const input = event.target as HTMLInputElement
    if (input) {
      input.value = input.value
        .trimStart()
        .replace(/\s\s+/g, ' ')
        .trimEnd()
      this.validate(input.value)
    }
  }

  onFocus() {
    this.isFocused = true
  }

  onBlur() {
    this.isFocused = false
  }

  formatCardNumber(value: string): string {
    const type = getCardType(value)

    if (type === 'Amex') {
      const clearedValue = value.replace(/\s?/g, '')
      const part1 = clearedValue.substring(0, 4)
      const part2 = clearedValue.length > 4 ? (' ' + clearedValue.substring(4, clearedValue.length >= 11 ? 11 : clearedValue.length)) : ''
      const part3 = clearedValue.length > 11 ? (' ' + clearedValue.substring(11, clearedValue.length)) : ''

      return `${part1}${part2}${part3}`
    } else {
      return value
        .replace(/\s?/g, '')
        .replace(/(\d{4})/g, '$1 ')
        .trim()
    }
  }

  /**
   * @description
   * Validates the input value and updates the errors model.
   * The errors model is an array of strings representing the validation errors.
   *
   * @param { string } value - The input value.
   */
  validate(value: string): void {
    if (!value && this.properties.required) {
      this.errors = ['This field is required.'];
      this.fieldChange.emit({
        value: '',
        errors: this.errors,
      });
      this.cardNumberChange.emit("");
      return;
    }

    const response = this.properties.validator.safeParse(value);
    this.errors = !response.success ? response.error.format()._errors : [];
    this.fieldChange.emit({
      value,
      errors: this.errors,
    });

    const isValid = this.errors.length === 0;

    if (isValid) {
      this.cardNumberChange.emit(this.getUnmaskedValue(value));
    } else {
      this.cardNumberChange.emit('');
    }
  }

  /**
   * @private
   * @description
   * Gets the card type based on the input value.
   * It removes the card types from the input value and updates the value model.
   *
   * @param { string } value - The input value.
   */
  private setCardType(value: string): void {
    this.removeCardTypes()
    switch (getCardType(value)) {
      case 'Visa':
        this.suffixIconElement.nativeElement.classList.add(
          'field__suffix-icon--visa-icon',
        )
        break
      case 'Mastercard':
        this.suffixIconElement.nativeElement.classList.add(
          'field__suffix-icon--mastercard-icon',
        )
        break
      case 'Amex':
        this.suffixIconElement.nativeElement.classList.add(
          'field__suffix-icon--amex-icon',
        )
        break
      case 'Discover':
        this.suffixIconElement.nativeElement.classList.add(
          'field__suffix-icon--discover-icon',
        )
        break
    }
  }

  /**
   * @private
   * @description
   * Removes the card types from the input value and updates the value model.
   */
  private removeCardTypes(): void {
    this.suffixIconElement.nativeElement.classList.remove(
      'field__suffix-icon--visa-icon',
    )
    this.suffixIconElement.nativeElement.classList.remove(
      'field__suffix-icon--mastercard-icon',
    )
    this.suffixIconElement.nativeElement.classList.remove(
      'field__suffix-icon--amex-icon',
    )
    this.suffixIconElement.nativeElement.classList.remove(
      'field__suffix-icon--discover-icon',
    )
  }

  focusInput() {
    const inputElement = this.creditCardElement.nativeElement
    inputElement.focus()
    const valueLength = inputElement.value.length
    inputElement.setSelectionRange(valueLength, valueLength)
  }

  private getUnmaskedValue(value: string): string {
    return value.replace(/\s/g, '');
  }
}

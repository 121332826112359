import {Component, EventEmitter, OnInit, Output, WritableSignal} from '@angular/core';
import {EventsManager} from "../../../events/events.manager";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {MemberAgreementComponent} from "../../modals/member-agreement/member-agreement.component";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators, ɵFormGroupRawValue,
  ɵGetProperty,
  ɵTypedOrUntyped
} from "@angular/forms";
import {SalesService} from "../../../services/sales.service";
import {PaymentInfoModel} from "../../../models/payment-info.model";
import { environment } from 'src/environments/environment';
import { defaultPortalPurchaseSummary, PortalPurchaseSummary } from 'src/app/models/sales.model';
import { FeatureFlagService, Features } from 'src/app/services/feature.service';
import {Subject, take} from 'rxjs';
import {Field} from "../../cc-field/credit-card-field.entity";
import { creditCardSchema } from "../../../helpers/credit-card.schema";

@Component({
  selector: 'app-card-info',
  templateUrl: './card-info.component.html',
  styleUrls: ['./card-info.component.scss']
})
export class CardInfoComponent implements OnInit {
readonly inputSubject = new Subject();
@Output() fieldChange = new EventEmitter<any>()
currentPurchase :PortalPurchaseSummary = defaultPortalPurchaseSummary;
billingAddress:any = {};
token:string = '';
cvv:string = '';
cardNum:string = '';
accepted:boolean = false;
isMedicalRequired:boolean = false;
isMedicalConfirmed:boolean = false;
showAutoRenewalDisclaimer:boolean = false;
autoRenewalRequired:boolean = false;
cardNumberMask:string = '0000 - 0000 - 0000 - 0009';
prefix:string = '';
cvvMask:string = '0000';
cvvPrefix:string = '';
cardNumberCtrl: AbstractControl<ɵGetProperty<ɵTypedOrUntyped<any, ɵFormGroupRawValue<any>, any>, "cardNumber">> | null | undefined;
cvvCntrl: AbstractControl<ɵGetProperty<ɵTypedOrUntyped<any, ɵFormGroupRawValue<any>, any>, "cardNumber">> | null | undefined;
creditCardBlurInterval: any;
cvvBlurInterval: any;
private readonly numericRegex = /[0-9]/
private readonly allowedKeys = [
  'ArrowLeft',
  'ArrowRight',
  'Backspace',
  'Delete',
  'Space',
  'Tab',
]
private errors: string[] = [];
isFocused = false;
readonly creditCardSchema = creditCardSchema
  formGroup: Record<string, Field> = {
    clientId: {
      value: '',
      errors: [],
    },
    amount: {
      value: '',
      errors: [],
    },
    cardNumber: {
      value: '',
      errors: [],
    },
    expirationDate: {
      value: '',
      errors: [],
    },
    cvv: {
      value: '',
      errors: [],
    },
    zipcode: {
      value: '',
      errors: [],
    }
  }
cardForm:FormGroup = new FormGroup<any>(
  {
    cardNumber: new FormControl('', [Validators.required]),
    expDate: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(5)]),
    cvv: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]),
    firstName: new FormControl('',[Validators.required]),
    lastName: new FormControl('',[Validators.required]),
    accountNumber: new FormControl(''),
    securityCode: new FormControl(''),
    response$bin: new FormControl(''),
    response$paypageRegistrationId: new FormControl(''),
    memAgree: new FormControl(this.accepted,Validators.required),
    commAgreement: new FormControl('true'),
    isMedicalConfirmed: new FormControl(this.isMedicalConfirmed,Validators.required),
    showAutoRenewalDisclaimer: new FormControl(this.autoRenewalRequired),
    cardNumberHidden: new FormControl(''),
    hiddenCvv: new FormControl('', [Validators.required, Validators.minLength(3)]),
  }
)
errorMessage:string = '';
showError:boolean = false;

captcha:string = environment.publicCaptchaKey;
  constructor(private em: EventsManager, private modalService: NgbModal, private salesService:SalesService, private featureFlagService : FeatureFlagService) { }
  paymentInfo:PaymentInfoModel = {cardNumber:'',cardType:'',expDate:'',cvv:'',lastFour:'',firstName:'',lastName:''};
  ngOnInit(): void {
    sessionStorage['payment'] = null;
    this.getCurrentPurchase(false);
    //show Medi-cal insurance agreement when practice is in CA or user belongs to CA
    if(this.em.applicants.primaryApplicant.state === 'CA'
    || this.em.officeInfo?.practice.address.state === 'CA'){
      this.isMedicalRequired = true;
    }


    if (this.em.officeInfo != null) {
      this.featureFlagService.getFeatureFlags(this.em.officeInfo.practice.uiMode).subscribe((features: any) => {
        if (features[Features.AutoRenewalDisclaimer] === true) {
          this.showAutoRenewalDisclaimer = true;
        }
      });
    }



    this.em.sendPaymentError.subscribe(
      (result) => {

        this.errorMessage = result.message;
        this.showError = true;
      }
    )

    this.em.sendPaymentTimeout.subscribe(
      (result) => {

        this.errorMessage = 'We are unable to process at this time, please try again';
        this.showError = true;
      }
    )

    this.em.acceptMemberAgreement.subscribe(
      (result:boolean) => {
        this.accepted = result;
        this.cardForm.get('memAgree')?.setValue(this.accepted);
      }
    )

    // this is for debugging, so leaving it in
    this.cardForm.statusChanges.subscribe(
      (status:any) => {
        // console.log('status: ', status);
        // for(const field in this.cardForm.controls) {
        //   const control = this.cardForm.get(field); //This gets the actual formControl.
        //   console.log(control);
        // }
      }
    )
  }
  onCardNumberChange(cardNumber: string) {
    this.cardForm.get('cardNumber')?.setValue(cardNumber);
  }
  setMemAgreement(){
    this.accepted = !this.accepted;
    this.cardForm.get('memAgree')?.setValue(this.accepted);
    //this.cardForm.setErrors({ 'invalid': this.accepted });
  }
  openMemberAgreement(){
    const modalRef: NgbModalRef = this.modalService.open(MemberAgreementComponent);
    modalRef.result.then(
      (result: any) => {
        //console.log(result)
        this.closeModal();
      },
      error => {
        console.log('dia error: ', error);
      }
    )
  }

  processPayment(){
    this.showError = false;
    this.errorMessage = '';
    if(this.token === ''){
      this.showError = true;
      this.errorMessage = 'Please complete the ReCaptcha to proceed';
      return;
    }
    this.paymentInfo.cardNumber = this.cardForm.get('cardNumber')?.value;
    this.paymentInfo.expDate = this.cardForm.get('expDate')?.value;
    this.paymentInfo.cvv = this.cardForm.get('cvv')?.value;
    this.paymentInfo.firstName = this.cardForm.get('firstName')?.value;
    this.paymentInfo.lastName = this.cardForm.get('lastName')?.value;
    this.paymentInfo.cardType = this.salesService.getCreditCardType(this.cardForm.get('cardNumber')?.value);
    this.paymentInfo.lastFour = this.cardForm.get('cardNumber')?.value.slice(12);
    let cardInfo:any = {'card':this.paymentInfo, 'check': null};

    sessionStorage.setItem('payment',JSON.stringify(cardInfo));

    this.em.sendSuccessfulPayment.pipe(take(1)).subscribe(
      (result) => {
        let cardInfo:any = {
          name: this.paymentInfo.firstName + ' ' + this.paymentInfo.lastName,
          paymentType: result.type,
          expirationDate: this.paymentInfo.expDate,
          payPageRegistrationId: result.paypageRegistrationId,
          lastFour: result.lastFour,
          zipCode: this.em.applicants.primaryApplicant.zipCode,
          address: this.em.applicants.primaryApplicant.address1,
          city: this.em.applicants.primaryApplicant.city,
          state: this.em.applicants.primaryApplicant.state
        }
        let update:any = {
          practiceId: this.em.officeInfo?.practiceId,
          shouldNotifyByMail: !this.cardForm.get('commAgreement')?.value,
          isPlanAutoRenewal: this.autoRenewalRequired,
          isMedicalConfirmed : this.cardForm.get('isMedicalConfirmed')?.value
        };
        this.salesService.completePurchase(update,cardInfo,this.token).subscribe(
          (result:any) => {

            if(result.error){
              this.errorMessage = result.error[0].message;
              this.showError = true;
            } else {
              this.sendToSuccess(true);
            }
            //
          },
          (error:any):void => {
            console.log(error);
            this.errorMessage = error;
            this.showError = true;
          }
        )

      }
    )
    this.salesService.tokenizePaymentMethod(this.paymentInfo.cardNumber,this.paymentInfo.cvv);

  }

  sendToSuccess(show:boolean){
    this.em.showSuccessScreen.emit(show);
  }

  closeModal() {
    // need stuff here
    this.modalService.dismissAll();

  }

  getCurrentPurchase(status:boolean){
    this.salesService.getPurchase(status).subscribe(
      (result:PortalPurchaseSummary) => {
        this.currentPurchase = result;
        this.autoRenewalRequired = this.currentPurchase.autoRenewalDetail.autoRenewalRequired;
        this.setAddress();
      }
    );
  }
  expFormat(event:any){
    if(this.cvv.length < 5 && event.target.value.length <= 5 && event.key !== 'Backspace') {
      if (event.target.value.length == 2) {
        event.target.value += '/';
      } else if (event.target.value.length == 5) {
        //event.target.value += '/';
      }
      this.cvv = event.target.value;
    } else if(event.key === 'Backspace'){
      this.cvv = event.target.value;
    } else {
      event.target.value = this.cvv;
    }
  }

  onAutoRenewalRequiredChanged() {
    this.autoRenewalRequired = !this.autoRenewalRequired;
    this.cardForm.get('showAutoRenewalDisclaimer')?.setValue(this.autoRenewalRequired);
  }

   onMedicalChanged(){
    this.isMedicalConfirmed = !this.isMedicalConfirmed;
    this.cardForm.get('isMedicalConfirmed')?.setValue(this.isMedicalConfirmed);
  }

  setAddress(){
    if(this.currentPurchase?.primaryApplicant?.address){

      this.billingAddress.address = this.currentPurchase.primaryApplicant.address;
      this.billingAddress.city = this.currentPurchase.primaryApplicant.city;
      this.billingAddress.state = this.currentPurchase.primaryApplicant.state;
      this.billingAddress.zipCode = this.currentPurchase.primaryApplicant.zipCode;
    } else {
      this.billingAddress.address = this.currentPurchase?.responsibleParty.address;
      this.billingAddress.city = this.currentPurchase?.responsibleParty.city;
      this.billingAddress.state = this.currentPurchase?.responsibleParty.state;
      this.billingAddress.zipCode = this.currentPurchase?.responsibleParty.zipCode;
    }
  }

  //left in to debug the form if needed
  cardNumber: Field | WritableSignal<Field> | undefined;
  getFormValidationErrors() {
    Object.keys(this.cardForm.controls).forEach(key => {
      const controlErrors: any = this.cardForm.get(key)?.errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
         console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }

  cardNumberBlur() {
    if (this.creditCardBlurInterval) {
      clearInterval(this.creditCardBlurInterval);
    }

    if (this.cardNumberMask != '0000 - 0000 - 0000 - 0009') {
      return;
    }

    this.cardForm.get('cardNumber')?.setValue(this.cardForm.get('cardNumberHidden')?.value);

    this.cardForm.get('cardNumberHidden')?.setValue('');
    let cardNumberMask = '';
    let prefix = '';

    if (this.cardForm.get('cardNumber')?.value && this.cardForm.get('cardNumber')?.value.length > 0) {
      const total = this.cardForm.get('cardNumber')?.value.length;
      if (total >= 12) {
        prefix = 'XXXX - XXXX - XXXX - ';
        cardNumberMask = '0009';
      } else {
        let counter = 0;
        cardNumberMask = '0';

        do {
          prefix = prefix + ((total >= (counter + 4)) ? 'XXXX - ' : 'XXXX'.substring(0, total - counter));
          counter = counter + ((total >= (counter + 4)) ? 4 : total - counter);
        } while (total > counter)
      }
    }

    if (this.cardForm.get('cardNumber')?.value && cardNumberMask == '0009') {
      this.cardForm.get('cardNumberHidden')?.setValue(this.cardForm.get('cardNumber')?.value.substring(12, this.cardForm.get('cardNumber')?.value.length));
    } else {
      this.cardForm.get('cardNumberHidden')?.setValue('');
    }

    this.cardNumberMask = cardNumberMask;
    this.prefix = prefix;

    this.cardNumberCtrl = this.cardForm.get('cardNumber');
  }

  cardNumberFocus() {
    if (this.cardNumberMask != '0000 - 0000 - 0000 - 0009') {
      this.cardForm.get('cardNumberHidden')?.setValue('');
      this.cardNumberMask = '0000 - 0000 - 0000 - 0009';
      this.prefix = '';
      this.cardForm.get('cardNumberHidden')?.setValue(this.cardForm.get('cardNumber')?.value);
      this.cardNumberCtrl = this.cardForm.get('cardNumberHidden');
      this.creditCardIdleInterval();
    }
  }

  creditCardIdleInterval() {
    if (this.creditCardBlurInterval) {
      clearInterval(this.creditCardBlurInterval);
    }

    this.creditCardBlurInterval = setInterval(() => {
      clearInterval(this.creditCardBlurInterval);

      this.cardNumberBlur();
    }, 3000);
  }

  cvvBlur() {
    if (this.cvvBlurInterval) {
      clearInterval(this.cvvBlurInterval);
    }

    if (this.cvvMask != '0000') {
      return;
    }

    this.cardForm.get('cvv')?.setValue(this.cardForm.get('hiddenCvv')?.value);

    this.cardForm.get('hiddenCvv')?.setValue('');
    let cvvMask = '';
    let prefix = '';
    let cvvStr = '';
      if (this.cardForm.get('cvv')?.value && this.cardForm.get('cvv')?.value.length > 0) {
        const total = this.cardForm.get('cvv')?.value.length;
        console.log('total: ', total);
        for(let i=0; i < total; i++) {
          cvvStr += 'X';
        }
        this.cardForm.get('hiddenCvv')?.setValue(cvvStr);
        console.log('cvv: ', cvvStr);
      }

    this.cvvMask = cvvMask;
    this.prefix = prefix;

    this.cvvCntrl = this.cardForm.get('cvv');
  }

  cvvFocus(){
    if (this.cvvMask != '0000') {
      this.cardForm.get('hiddenCvv')?.setValue('');
      this.cvvMask = '0000';
      this.prefix = '';
      this.cardForm.get('hiddenCvv')?.setValue(this.cardForm.get('cvv')?.value);
      this.cardNumberCtrl = this.cardForm.get('hiddenCvv');
      this.cvvInterval();
    }
  }
  cvvInterval(){
    if (this.cvvBlurInterval) {
      clearInterval(this.cvvBlurInterval);
    }

    this.cvvBlurInterval = setInterval(() => {
      clearInterval(this.cvvBlurInterval);

      this.cvvBlur();
    }, 3000);
  }

  cvvKeyPress() {
    this.cvvInterval();
    this.cvvFocus();
  }

  cardNumberKeyPress() {
    this.creditCardIdleInterval();
    this.cardNumberFocus();
  }

  onCaptchaTimeout() {
    this.cardForm.get('token')?.setValue('');
  }
}

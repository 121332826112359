import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { PlanDetailComponent } from './components/plan-detail/plan-detail.component';
import { OfficeComponent } from './components/office/office.component';
import { PurchaseComponent } from './components/purchase/purchase.component';
import { DetailCardComponent } from './components/plan-detail/detail-card/detail-card.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LegalTextComponent } from './components/legal-text/legal-text.component';
import { HeroComponent } from './components/hero/hero.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SubheaderComponent } from './components/header/subheader/subheader.component';
import { LandingComponent } from './components/landing/landing.component';
import { FaqComponent } from './components/faq/faq.component';
import { SupportComponent } from './components/support/support.component';
import { FaqItemComponent } from './components/common/faq-item/faq-item.component';
import { InviteAFriendComponent } from './components/modals/invite-a-friend/invite-a-friend.component';
import { CardItemComponent } from './components/common/card-item/card-item.component';
import { HeaderTextComponent } from './components/common/header-text/header-text.component';
import {EventsManager} from "./events/events.manager";
import {PlansService} from "./services/plans.service";
import { HttpClientModule, HTTP_INTERCEPTORS} from "@angular/common/http";
import { PhonePipe } from "./pipes/phone.pipe";
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {PlanCardComponent} from "./components/office/plan-card/plan-card.component";
import { DataService } from './services/data.service';
import { CardContainerComponent } from './components/common/card-container/card-container.component';
import { SingleMemberComponent } from './components/purchase/single-member/single-member.component';
import { MultiMemberComponent } from './components/purchase/multi-member/multi-member.component';
import { CardInfoComponent } from './components/purchase/card-info/card-info.component';
import { FeeScheduleComponent } from './components/fee-schedule/fee-schedule.component';
import { SuccessComponent } from './components/purchase/success/success.component';
import { MemberAgreementComponent } from './components/modals/member-agreement/member-agreement.component';
import { SignInComponent } from './components/modals/sign-in/sign-in.component';
import { TimerPipe } from './pipes/timer.pipe';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import {SessionInterceptor} from "./services/interceptors/session.interceptor";
import { ProgressBarComponent } from './components/purchase/progress-bar/progress-bar.component';
import {DatePipe} from "@angular/common";
import { LoadingComponent } from './components/common/loading/loading.component';
import {LoadingInterceptor} from "./services/interceptors/loading.interceptor";
import {ObjToKeysPipe} from "./pipes/objToKeys";
import { ErrorBannerComponent } from './components/common/error-banner/error-banner-component';
import { MemberInfoComponent } from './components/purchase/member-info/member-info.component';
import {RangePipe} from "./pipes/range";
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';
import { FeatureFlagService } from './services/feature.service';
import {CcFieldComponent} from "./components/cc-field/cc-field.component";

const maskConfig: Partial<IConfig> = {
  validation: false,
};
// @ts-ignore
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PlanDetailComponent,
    OfficeComponent,
    PurchaseComponent,
    DetailCardComponent,
    HeaderComponent,
    FooterComponent,
    LegalTextComponent,
    HeroComponent,
    SubheaderComponent,
    LandingComponent,
    FaqComponent,
    SupportComponent,
    FaqItemComponent,
    InviteAFriendComponent,
    CardItemComponent,
    HeaderTextComponent,
    PlanCardComponent,
    PhonePipe,
    TimerPipe,
    ObjToKeysPipe,
    RangePipe,
    CardContainerComponent,
    FeeScheduleComponent,
    SingleMemberComponent,
    MultiMemberComponent,
    CardInfoComponent,
    SuccessComponent,
    MemberAgreementComponent,
    SignInComponent,
    ProgressBarComponent,
    LoadingComponent,
    ErrorBannerComponent,
    MemberInfoComponent,
    PrivacyPolicyComponent,
    TermsOfUseComponent,
    CcFieldComponent
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgbModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        RecaptchaFormsModule,
        RecaptchaModule,
        BrowserAnimationsModule,
        NgxMaskModule.forRoot(maskConfig)
    ],
  providers: [EventsManager, PlansService, DatePipe,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha.siteKey,
      } as RecaptchaSettings,
    },
    DataService,
    FeatureFlagService,
    {provide: HTTP_INTERCEPTORS, useClass: SessionInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

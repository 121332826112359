import { amexRegex, discoverRegex, masterCardRegex, visaRegex } from './credit-card.helper'
import {z} from "zod";

export const creditCardSchema: z.ZodEffects<z.ZodString, string, string> = z
  .string()
  .transform((value: string, ctx: { addIssue: (arg0: { code: any; message: string }) => void }) => {
    const cleanedValue = value.replace(/\s+/g, '')

    if (!value) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Card number is required.',
      })
    } else if (cleanedValue) {
      const firstFourDigits = cleanedValue.substring(0, 4)
      const cardLength = cleanedValue.length

      let isValidPrefix = false
      let minLength = 0
      let maxLength = 0

      if (visaRegex.test(firstFourDigits)) {
        isValidPrefix = true
        minLength = 16
        maxLength = 16
      } else if (masterCardRegex.test(firstFourDigits)) {
        isValidPrefix = true
        minLength = 16
        maxLength = 16
      } else if (amexRegex.test(firstFourDigits)) {
        isValidPrefix = true
        minLength = 15
        maxLength = 15
      } else if (discoverRegex.test(firstFourDigits)) {
        isValidPrefix = true
        minLength = 16
        maxLength = 16
      }

      if (!isValidPrefix) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Invalid card number',
        })
      } else if (cardLength < minLength || cardLength > maxLength) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            minLength === maxLength
              ? `Card number must be ${minLength} digits`
              : `Card number must be between ${minLength} and ${maxLength} digits`,
        })
      } else if (!validateCardNumber(cleanedValue)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Invalid card number',
        })
      }
    }
    return value
  })
const validateCardNumber = (cardNumber: string): boolean => {
  // Convert the card number string into an array of digits
  const digits = cardNumber.split('').map(Number);

  // Remove the last digit (check digit)
  const checkDigit = digits.pop();

  // Reverse the digits
  digits.reverse();

  // Double every second digit and subtract 9 if the result is greater than 9
  const sum = digits.reduce((acc, digit, idx) => {
    if (idx % 2 === 0) {
      digit *= 2;
      if (digit > 9) digit -= 9;
    }
    return acc + digit;
  }, 0);

  // Add the check digit back to the sum
  const total = sum + (checkDigit || 0);

  // If the total modulo 10 is 0, the card number is valid
  return total % 10 === 0;
}
